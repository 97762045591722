const windowIsDefined = () => typeof window !== "undefined";
if (windowIsDefined()) {
  window.dataLayer = window?.dataLayer ?? [];
}
const push = (object) => {
  if (windowIsDefined() && window?.dataLayer) {
    window.dataLayer.push(object);
  }
};
const addPaymentInfo = (params) => push({ ...params, event: "add_payment_info" /* ADD_PAYMENT_INFO */ });
const addShippingInfo = (params) => push({ ...params, event: "add_shipping_info" /* ADD_SHIPPING_INFO */ });
const addToCart = (params) => push({ ...params, event: "add_to_cart" /* ADD_TO_CART */ });
const addToWishlist = (params) => push({ ...params, event: "add_to_wishlist" /* ADD_TO_WISHLIST */ });
const beginCheckout = (params) => push({ ...params, event: "begin_checkout" /* BEGIN_CHECKOUT */ });
const earnVirtualCurrency = (params) => push({ ...params, event: "earn_virtual_currency" /* EARN_VIRTUAL_CURRENCY */ });
const exception = (params) => push({ ...params, event: "exception" /* EXCEPTION */ });
const generateLead = (params) => push({ ...params, event: "generate_lead" /* GENERATE_LEAD */ });
const joinGroup = (params) => push({ ...params, event: "join_group" /* JOIN_GROUP */ });
const levelEnd = (params) => push({ ...params, event: "level_end" /* LEVEL_END */ });
const levelStart = (params) => push({ ...params, event: "level_start" /* LEVEL_START */ });
const levelUp = (params) => push({ ...params, event: "level_up" /* LEVEL_UP */ });
const login = (params) => push({ ...params, event: "login" /* LOGIN */ });
const postScore = (params) => push({ ...params, event: "post_score" /* POST_SCORE */ });
const purchase = (params) => push({ ...params, event: "purchase" /* PURCHASE */ });
const refund = (params) => push({ ...params, event: "refund" /* REFUND */ });
const removeFromCart = (params) => push({ ...params, event: "remove_from_cart" /* REMOVE_FROM_CART */ });
const search = (params) => push({ ...params, event: "search" /* SEARCH */ });
const selectContent = (params) => push({ ...params, event: "select_content" /* SELECT_CONTENT */ });
const selectItem = (params) => push({ ...params, event: "select_item" /* SELECT_ITEM */ });
const selectPromotion = (params) => push({ ...params, event: "select_promotion" /* SELECT_PROMOTION */ });
const share = (params) => push({ ...params, event: "share" /* SHARE */ });
const signUp = (params) => push({ ...params, event: "sign_up" /* SIGN_UP */ });
const spendVirtualCurrency = (params) => push({ ...params, event: "spend_virtual_currency" /* SPEND_VIRTUAL_CURRENCY */ });
const tutorialBegin = (params) => push({ ...params, event: "tutorial_begin" /* TUTORIAL_BEGIN */ });
const tutorialComplete = (params) => push({ ...params, event: "tutorial_complete" /* TUTORIAL_COMPLETE */ });
const unlockAchievement = (params) => push({ ...params, event: "unlock_achievement" /* UNLOCK_ACHIEVEMENT */ });
const viewCart = (params) => push({ ...params, event: "view_cart" /* VIEW_CART */ });
const viewItem = (params) => push({ ...params, event: "view_item" /* VIEW_ITEM */ });
const viewItemList = (params) => push({ ...params, event: "view_item_list" /* VIEW_ITEM_LIST */ });
const viewPromotion = (params) => push({ ...params, event: "view_promotion" /* VIEW_PROMOTION */ });
export const useTagmanager = () => {
  return {
    push,
    addPaymentInfo,
    addShippingInfo,
    addToCart,
    addToWishlist,
    beginCheckout,
    earnVirtualCurrency,
    exception,
    generateLead,
    joinGroup,
    levelEnd,
    levelStart,
    levelUp,
    login,
    postScore,
    purchase,
    refund,
    removeFromCart,
    search,
    selectContent,
    selectItem,
    selectPromotion,
    share,
    signUp,
    spendVirtualCurrency,
    tutorialBegin,
    tutorialComplete,
    unlockAchievement,
    viewCart,
    viewItem,
    viewItemList,
    viewPromotion
  };
};
var RecommendedEvents = /* @__PURE__ */ ((RecommendedEvents2) => {
  RecommendedEvents2["EARN_VIRTUAL_CURRENCY"] = "earn_virtual_currency";
  RecommendedEvents2["EXCEPTION"] = "exception";
  RecommendedEvents2["GENERATE_LEAD"] = "generate_lead";
  RecommendedEvents2["JOIN_GROUP"] = "join_group";
  RecommendedEvents2["LEVEL_END"] = "level_end";
  RecommendedEvents2["LEVEL_START"] = "level_start";
  RecommendedEvents2["LEVEL_UP"] = "level_up";
  RecommendedEvents2["LOGIN"] = "login";
  RecommendedEvents2["POST_SCORE"] = "post_score";
  RecommendedEvents2["SEARCH"] = "search";
  RecommendedEvents2["SELECT_CONTENT"] = "select_content";
  RecommendedEvents2["SHARE"] = "share";
  RecommendedEvents2["SIGN_UP"] = "sign_up";
  RecommendedEvents2["SPEND_VIRTUAL_CURRENCY"] = "spend_virtual_currency";
  RecommendedEvents2["TUTORIAL_BEGIN"] = "tutorial_begin";
  RecommendedEvents2["TUTORIAL_COMPLETE"] = "tutorial_complete";
  RecommendedEvents2["UNLOCK_ACHIEVEMENT"] = "unlock_achievement";
  return RecommendedEvents2;
})(RecommendedEvents || {});
var EcommerceEvents = /* @__PURE__ */ ((EcommerceEvents2) => {
  EcommerceEvents2["ADD_PAYMENT_INFO"] = "add_payment_info";
  EcommerceEvents2["ADD_SHIPPING_INFO"] = "add_shipping_info";
  EcommerceEvents2["ADD_TO_CART"] = "add_to_cart";
  EcommerceEvents2["ADD_TO_WISHLIST"] = "add_to_wishlist";
  EcommerceEvents2["BEGIN_CHECKOUT"] = "begin_checkout";
  EcommerceEvents2["PURCHASE"] = "purchase";
  EcommerceEvents2["REFUND"] = "refund";
  EcommerceEvents2["REMOVE_FROM_CART"] = "remove_from_cart";
  EcommerceEvents2["SELECT_ITEM"] = "select_item";
  EcommerceEvents2["SELECT_PROMOTION"] = "select_promotion";
  EcommerceEvents2["VIEW_CART"] = "view_cart";
  EcommerceEvents2["VIEW_ITEM"] = "view_item";
  EcommerceEvents2["VIEW_ITEM_LIST"] = "view_item_list";
  EcommerceEvents2["VIEW_PROMOTION"] = "view_promotion";
  return EcommerceEvents2;
})(EcommerceEvents || {});
