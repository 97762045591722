export default defineNuxtPlugin(async() => {
    const authStore = useAuthStore();
    const { push } = useTagmanager();
    const cookie = useCookie('XSRF-TOKEN');

    if (!authStore.loggedIn && cookie.value) {
        await authStore.getUser();
        
        push({
            event: 'reauthenticate'
        });
    } else if (!cookie.value) {
        await $fetch(useRuntimeConfig().public.apiSanctumEndpoint, {
            credentials: 'include'
        });

        refreshCookie('XSRF-TOKEN');
    }
});
