<template>
    <div
        class="error"
        data-span-all
    >
        <div v-if="error && error.statusCode === 404">
            <h1>This page doesn't exist</h1>
            <BaseButton
                :element="'a'"
                href="/"
            >
                Go to the homepage
            </BaseButton>
        </div>
        <div v-else>
            <h1>An error occurred</h1>
            <p v-if="error && error.message">{{ error.message }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less" scoped>
.error {
    padding: 1rem;
    min-height: 80dvh;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
    }
}
</style>
