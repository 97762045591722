<template>
    <div class="template">
        <main
            id="main-content-landmark"
            class="template__grid bento-grid"
        >
            <slot
                v-if="!!$slots.hero"
                name="hero"
            />
            <slot />
        </main>
    </div>
</template>

<style lang="less">
.template {
    --grid-maxWidth-current: var(--grid-maxWidth-pageMd);
    display: grid;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-maxWidth-current);

    &:not(.template--wide) .section[data-width='bleed'] .section__container .section__body {
        @media ( min-width: 1160px ) { // come fight me if you don't like this code -> schoolplein om 6u met Dian en Koos
            margin-right: calc((var(--100vw) - var(--grid-maxWidth-current)) / 2 * -1 - var(--spacing-md));
            margin-left: calc((var(--100vw) - var(--grid-maxWidth-current)) / 2 * -1 - var(--spacing-md));
        }
    }
}

.template--wide {
    --grid-maxWidth-current: var(--grid-maxWidth-page);
    width: 100%;

    .section[data-width='bleed'] .section__container .section__body {
        @media ( min-width: 1440px ) { // come fight me if you don't like this code -> schoolplein om 6u
            margin-right: calc((var(--100vw) - var(--grid-maxWidth-current)) / 2 * -1 - var(--spacing-md));
            margin-left: calc((var(--100vw) - var(--grid-maxWidth-current)) / 2 * -1 - var(--spacing-md));
        }
    }
}

.template--narrow {
    --grid-maxWidth-current: var(--grid-maxWidth-content);
    width: 100%;
}

.template--gap-lg {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;

    .template__grid {
        row-gap: var(--spacing-lg);

        @media @q-md-min {
            row-gap: var(--spacing-xl);
        }
    }

    @media @q-md-min {
        padding-top: 3.125rem;
        padding-bottom: 3.125rem;
    }
}
</style>
