import { useAuthStore } from '~/store/auth';

const restrictedRouteNames = [
    'account',
    'favorites',
    'uploads',
    'upload-checklist',
    'upload-preview',
    'upload-uploading',
    'upload-video',
    'upload-thank-you',
];

export default defineNuxtRouteMiddleware((to) => {
    const authStore = useAuthStore();

    if (!authStore.loggedIn) {
        if (restrictedRouteNames.includes(to.name)) {
            return '/';
        }
    }
});