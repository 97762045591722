const errorMessages = [
    'Importing a module script failed', // safari
    'Failed to fetch dynamically imported module', // edge & chrome
    'error loading dynamically imported module' // firefox
];

export default defineNuxtPlugin((nuxtApp) => {
    const reloadApp = () => {
        reloadNuxtApp({ persistState: true });
    };

    nuxtApp.hook('vue:error', (error) => {
        if (errorMessages.some(message => error.message.includes(message))) {
            reloadApp();
        }
    });
});
