import LogoutMutation from '~/graphql/mutations/auth/logout.graphql';
import UserQuery from '~/graphql/queries/user.graphql';

export const useAuthStore = defineStore('auth-store', () => {
    const user = ref(null);
    const tempEmail = ref('');
    const verificationSent = ref(false);
    
    const loggedIn = computed(() => !!user.value);
    const favoriteVideos = ref([]);

    const specialisations = computed(() => {
        if (user.value) {
            return user.value.specialisations.map((specialisation) => specialisation.id);
        }

        return [];
    });

    const logout = async() => {
        useNuxtApp().runWithContext(async() => {
            const { execute } = useAsyncMutation('logout');

            const { data } = await execute({
                mutation: LogoutMutation
            });

            if (data.value?.data?.logout?.status === 'ok') {
                reloadNuxtApp({ force: true });
            }
        });
    };

    const getUser = async(immediate = true) => {
        return await useAsyncQuery(
            { query: UserQuery, cachePolicy: 'network-only' },
            {
                onComplete: async({data, isDone }) => {
                    if (isDone?.value && data.value?.user) {
                        user.value = data.value.user;

                        if (user.value?.favoriteVideos) {
                            favoriteVideos.value = user.value.favoriteVideos.map((video) => video.id);
                        }
                    }
                },
                immediate
            }
        );
    };

    return {
        user,
        specialisations,
        favoriteVideos,
        tempEmail,
        verificationSent,
        loggedIn,
        logout,
        getUser
    };
});
