import { inject, ref, toValue } from "vue";
import { useMutation } from "villus";
import { useAsyncData } from "#app";
import { dnGraphQlClient } from "../keys.mjs";
export function useAsyncMutation(key) {
  const client = inject(dnGraphQlClient);
  const opts = ref();
  const { data, pending, error, execute: useAsyncDataExecute } = useAsyncData(key, async () => {
    const { execute: villusExecute } = useMutation(opts.value?.mutation, { client });
    const { data: villusData, error: villusError } = await villusExecute(toValue(opts.value?.variables));
    return { data: villusData, error: villusError };
  }, { immediate: false });
  const ownExecute = async (options) => {
    opts.value = options;
    await useAsyncDataExecute();
    return { data, pending, error };
  };
  return { data, pending, error, execute: ownExecute };
}
