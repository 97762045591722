import { villusBugsnag } from '@digitalnatives/villus-plugins';
import { defaultPlugins } from 'villus';
import { multipart } from '@villus/multipart';
import villusSanctum from '~/villus/villus-sanctum';

export default defineGraphqlConfig(() => {
    const config = useRuntimeConfig();

    return {
        villus: {
            url: config.public.apiEndpoint,
            use: [
                villusSanctum(),
                villusBugsnag(),
                multipart(),
                ...defaultPlugins(),
            ],
        }
    };
});
