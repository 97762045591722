<template>
    <NuxtLayout>
        <VitePwaManifest />
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup>
useGtm();
</script>
