<template>
    <div class="spinner" />
</template>

<script setup></script>

<style lang="less">
.spinner {
    --size: 20px;
    --lineWidth: calc(var(--size) * .1);
    --color-shade-100: var(--color-soft-blue-shade-100);
    --color-shade-400: var(--color-soft-blue-shade-400);
    --color: var(--color-soft-blue);
    --background-color: var(--color-muted-gray);

    position: relative;
    height: var(--size);
    width: var(--size);
    box-sizing: border-box;
    background: conic-gradient(
        from 90deg at 50% 50%,
        var(--color-shade-100) 0deg,
        var(--color-shade-400) 0.04deg,
        var(--color) 360deg
    );
    border-radius: 50%;
    animation: 1s rotate infinite linear, .5s spinnerFadeIn ease-in-out forwards;

    opacity: 0;
}

.spinner:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: calc(var(--size) - var(--lineWidth) * 2);
    width: calc(var(--size) - var(--lineWidth) * 2);
    background: var(--background-color);
    border-radius: 50%;
}

.spinner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: var(--lineWidth);
    width: var(--lineWidth);
    background: var(--color);
    border-radius: 50%;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinnerFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
