<template>
    <NuxtLayout
        class="error"
        name="default"
    >
        <PageTemplate>
            <ErrorMessage :error="error" />
        </PageTemplate>
    </NuxtLayout>
</template>

<script>
import ErrorMessage from '~/components/ErrorMessage/ErrorMessage.vue';

export default {
    components: {
        ErrorMessage
    },

    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less"></style>
