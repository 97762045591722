import { throttle } from 'lodash-es';

function setVars() {
    document.documentElement.style.setProperty('--100vh', `${document.documentElement.clientHeight}px`);
    document.documentElement.style.setProperty('--100vw', `${document.documentElement.clientWidth}px`);
}

export default defineNuxtPlugin(() => {
    setVars();
    window.addEventListener('resize', throttle(() => {
        setVars();
    }, 10));
});