import { definePlugin } from 'villus';

const villusSanctumPlugin = () => {
    const config = useRuntimeConfig().public;

    const fetchXsrfToken = async() => {
        await $fetch(config.apiSanctumEndpoint, {
            credentials: 'include'
        });
    };

    const cookie = useCookie('XSRF-TOKEN', {
        watch: true
    });

    const requestHeaders = useRequestHeaders(['cookie']);

    const origin = useRequestURL().origin;

    return definePlugin(async({ opContext, operation }) => {
        refreshCookie('XSRF-TOKEN');

        if (
            (operation?.type !== 'mutation') ||
            (
                !operation?.variables ||
                !('file' in operation.variables)
            )
        ) {
            opContext.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
        }

        opContext.credentials = 'include';

        opContext.headers = {
            ...opContext.headers,
            'X-Requested-With': 'XMLHttpRequest',
            'Referer': origin,
        };

        if (requestHeaders.cookie) {
            opContext.headers.Cookie = requestHeaders.cookie;
        }

        if (cookie.value) {
            opContext.headers['X-XSRF-TOKEN'] = cookie.value;
        } else if (process.client) {
            await fetchXsrfToken();

            refreshCookie('XSRF-TOKEN');

            if (cookie.value) {
                opContext.headers['X-XSRF-TOKEN'] = cookie.value;
            }
        }
    });
};

export default villusSanctumPlugin;
